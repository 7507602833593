// import {Link} from 'react-router-dom'
import {FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa"
import logo from '../assets/img/brand/logo.svg'
import { Link } from "react-router-dom"


function Footer() {
const currentYear = new Date().getFullYear();
  return (
            <footer className="d-flex pb-5 pt-6 pt-md-7 border-top border-light bg-primary">
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                    <p><strong>Zicstack</strong>, comprises a proficient and passionate group encompassing designers, developers, project managers, writers, and artists. Each client collaborates seamlessly with our team, collectively navigating challenges and celebrating achievements.
                    <br />
                    Our compact yet dynamic team actively engages in the creative landscape, maintaining a perpetual curiosity about emerging trends, and exuding an enjoyable atmosphere.</p>
                    <ul className="d-flex list-unstyled mb-5 mb-lg-0">
                        <li className="mr-2">
                            <Link to="https://twitter.com/Zicstack" 
                                className="btn btn-icon-only btn-pill btn-primary" aria-label="twitter social link"
                                data-toggle="tooltip" data-placement="top" title="Follow @Zicstack on Twitter">
                                <span>
                                    <FaTwitter color="#00acee"/>
                                </span>
                            </Link>
                        </li>
                        <li className="mr-2">
                            <Link to="https://web.facebook.com/Zicstack-106352705563950" 
                                className="btn btn-icon-only btn-pill btn-primary" aria-label="facebook social link"
                                data-toggle="tooltip" data-placement="top" title="Like @Zicstack on Facebook">
                                <span><FaLinkedinIn color="#0e76a8"/></span>
                            </Link>
                        </li>
                        <li className="mr-2">
                            <Link to="https://instagram.com/zicstack" 
                                className="btn btn-icon-only btn-pill btn-primary" aria-label="github social link"
                                data-toggle="tooltip" data-placement="top">
                                <span aria-hidden="true"><FaInstagram color="#bc2a8d"/></span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="col-6 col-lg-2 mb-5 mb-lg-0">
                    <h5>Zicstack</h5>
                    <ul className="footer-links list-unstyled mt-2">
                        <li className="mb-1"><Link className="p-2"  to="https://zicstack.net/blog">Blog</Link></li>
                        {/* <li className="mb-1"><a className="p-2" 
                                href="https://zicstack.net/products">Products</a>
                        </li> */}
                        <li className="mb-1"><Link className="p-2"  to="/about">About Us</Link>
                        </li>
                        <li><Link className="p-2"  to="/contact">Contact Us</Link></li>
                    </ul>
                </div>
                <div className="col-6 col-lg-2 mb-5 mb-lg-0">
                    <h5>Other</h5>
                    <ul className="footer-links list-unstyled mt-2">
                        {/* <li className="mb-1"><a className="p-2"
                                href="https://zicstack.net/docs/pixel-bootstrap/getting-started/overview/"
                                >Docs
                                <span className="badge badge-gray text-uppercase ml-2">v1.0</span></a>
                        </li>
                        <li className="mb-1">
                            <a className="p-2" href="https://zicstack.net/docs/pixel-bootstrap/getting-started/changelog"
                                >Changelog</a>
                        </li>
                        <li className="mb-1">
                            <a className="p-2"  href="https://zicstack.net/licensing">License</a>
                        </li> */}
                        <li>
                            <Link className="p-2" 
                                to="mailto:hello@zicstack.net">Support</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-lg-4 mb-5 mb-lg-0">
                    <h5>Subscribe</h5>
                    <p className="text-gray font-small mt-2">Join our mailing list. We write rarely, but only the best
                        content.</p>
                    <form action="#">
                        <div className="form-row mb-2">
                            <div className="col-12">
                                <label className="h6 font-weight-normal d-none" htmlFor="exampleInputEmail3">Email
                                    address</label>
                                <input type="email" className="form-control mb-2" placeholder="example@company.com" 
                                name="email" aria-label="Subscribe form" id="exampleInputEmail3" required />
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary btn-block" data-loading-text="Sending">
                                    <span>Subscribe</span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <p className="text-gray font-small m-0">We’ll never share your details. See our <Link className="text-black"
                            to="#">Privacy Policy</Link></p>
                </div>
            </div>
            <hr className="my-5" />
            <div className="row">
                <div className="col">
                    <Link to="/"  className="d-flex justify-content-center">
                        <img src={logo} height="25" className="mb-3" alt="Zicstack Logo" />
                    </Link>
                    <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                        <p className="font-weight-normal font-small mb-0">Copyright © Zicstack - 
                            <span className="current-year"> {currentYear}</span>. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer

        